import React from "react"
import { graphql } from 'gatsby'

import { Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Basic({ data })  {
  const post = data.nodePage
  return (
    <Layout>
      <br />
      <h1 id="texttitleLayout" className="mx-auto my-0 cssanimation sequence fadeInBottom">{post.title}</h1>
      <br />
      <SEO title={post.title} />
      <Container className="">
        <div dangerouslySetInnerHTML={{ __html: post.body.value }}></div>
      </Container>
    </Layout>
  )
}
export const query = graphql`
    query ($id: String!) {
      nodePage(id: { eq: $id }) {
        title
        body {
          value
        }
        created
      }
    }
  `